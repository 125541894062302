import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { codmanUserAuthGuard } from "@codman/shared/data-access-authorization";
import { AccessDeniedComponent } from "@codman/shared/feature-static-pages";
import { RegistryRedirectComponent } from "@codman/shared/feature-redirect";
import { auth0GuardCanActivate, auth0GuardCanLoad } from "@codman/shared/util-authorization";

const routes: Routes = [
    {
        path: "",
        pathMatch: "full",
        component: RegistryRedirectComponent,
    },
    {
        path: "access-denied",
        component: AccessDeniedComponent,
    },
    {
        path: ":registryId",
        loadChildren: () =>
            import("@codman/descriptives/dashboard").then(m => m.DescriptivesDashboardModule),
        canLoad: [auth0GuardCanLoad],
        canActivate: [auth0GuardCanActivate, codmanUserAuthGuard],
    },
    {
        path: "**",
        pathMatch: "full",
        component: RegistryRedirectComponent,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            routes, // This is needed when changing registry and/or subset, but page id remains the same
            { paramsInheritanceStrategy: "always" },
        ),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
